//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

.react-select-container {
  .react-select__control {
    background-color: var(--bg-secondary);
    border-color: var(--border-color);
    transition: none;

    &:hover {
      border-color: var(--border-color);
    }
  }

  .react-select__menu {
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
  }

  .react-select__option {
    background-color: var(--bg-secondary);

    &:hover {
      background-color: var(--bg-primary);
    }
  }

  .react-select__indicator-separator {
    background-color: var(--border-color);
  }

  .react-select__placeholder,
  .react-select__single-value {
    color: var(--text-primary);
  }
}

// Toast customizations
.toast {
  min-width: 300px;
  max-width: 400px;
  border-radius: 0.25rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

  transition: transform 1s ease-out, opacity 1s ease-out;

  .toast-header {
    padding: 0.5rem 0.75rem;
    font-weight: 600;
    border-bottom: 1px solid $gray-200;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close {
      color: $gray-600;
      font-size: 1.25rem;
      font-weight: 200;
      line-height: 1;
      opacity: 0.75;

      &:hover {
        color: $gray-900;
        opacity: 1;
      }
    }
  }

  .toast-body {
    padding: 0.75rem;
    color: $white;
  }
}

.toast.bg-info {
  .toast-header {
    background-color: $info;
    color: $white;
  }
}

.toast.bg-success {
  .toast-header {
    background-color: $success;
    color: $white;
  }
}

.toast.bg-warning {
  .toast-header {
    background-color: $warning;
    color: $white;
  }
}

.toast.bg-danger {
  .toast-header {
    background-color: $danger;
    color: $white;
  }
}

.toast-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.toast-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.toast-exit {
  opacity: 1;
  transform: translateY(0);
}

.toast-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}

.my_popover {
  background: transparent !important;
  padding: 0 !important;
}

.gradiant-bg {
  background-image: linear-gradient(
    290deg,
    hsl(55deg 100% 50%) 0%,
    hsl(51deg 100% 50%) 9%,
    hsl(47deg 100% 50%) 18%,
    hsl(43deg 100% 50%) 27%,
    hsl(39deg 100% 50%) 36%,
    hsl(36deg 100% 50%) 45%,
    hsl(32deg 100% 49%) 55%,
    hsl(28deg 100% 48%) 64%,
    hsl(24deg 100% 47%) 73%,
    hsl(19deg 100% 46%) 82%,
    hsl(13deg 98% 45%) 91%,
    hsl(0deg 87% 46%) 100%
  );
}

.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 50px;
  width: 50px;
  position: relative;
  border: 4px solid #f1416c;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f1416c;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0;
  }

  25% {
    height: 0;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0;
  }
}

.tooltip-inner {
  max-width: 200px; /* Set your desired maximum width */
  white-space: normal; /* Allow text to wrap */
}
